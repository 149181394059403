

















































import { useGetters } from '@u3u/vue-hooks'
import { defineComponent, reactive, ref } from '@vue/composition-api'

import { userGdpr } from '@/inc/endpoints'
import callApi from '@/composables/fetchapi'

import FeedbackMessage from '@/components/ui/FeedbackMessage.vue'
import VInput from '@/components/form/VInput.vue'
import VSelect from '@/components/form/VSelect.vue'
import VTextarea from '@/components/form/VTextarea.vue'
import { NotificationSeverity } from '@/inc/types'

export default defineComponent({
  name: 'gdpr',
  components: { VInput, VSelect, VTextarea, FeedbackMessage },
  props: {},

  setup() {
    const { i18n } = useGetters(['i18n'])
    const { user } = useGetters('user', ['user'])
    const formData = reactive({
      email: user.value.contact?.email || '',
      choice: '',
      message: '',
    })

    const form = ref<HTMLFormElement>()
    const isLoading = ref(false)
    const feedbackMessage = ref<{ type: NotificationSeverity; htmltext: string } | null>()
    const onSubmit = () => {
      feedbackMessage.value = null
      form.value?.reportValidity()

      isLoading.value = true
      callApi(userGdpr, { method: 'post', data: formData })
        .then(() => {
          feedbackMessage.value = {
            type: 'success',
            htmltext: 'common.formSuccess',
          }
        })
        .catch(() => {
          feedbackMessage.value = {
            type: 'error',
            htmltext: 'common.formError',
          }
        })
      // .finally(() => {
      //   isLoading.value = false
      // })
    }

    return {
      form,
      i18n,
      isLoading,
      feedbackMessage,
      onSubmit,
      user,
      formData,
    }
  },
})
