













































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'v-textarea',
  props: {
    label: String,
    required: {
      type: Boolean,
      default: false,
    },
    name: String,
    id: String,
    placeholder: String,
    errors: {
      type: Array,
      default: () => [],
    },
  },

  setup() {
    return {}
  },
})
