import { render, staticRenderFns } from "./VSelect.vue?vue&type=template&id=518a8fc5&scoped=true&"
import script from "./VSelect.vue?vue&type=script&lang=ts&"
export * from "./VSelect.vue?vue&type=script&lang=ts&"
import style0 from "./VSelect.vue?vue&type=style&index=0&id=518a8fc5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "518a8fc5",
  null
  
)

export default component.exports