















import axios from 'axios'
import { computed, defineComponent, ref } from '@vue/composition-api'

import Gdpr from '@/components/forms/Gdpr.vue'

export default defineComponent({
  name: 'legal',
  components: { Gdpr },
  props: {},

  setup(props, ctx) {
    const container = ref('')

    const apiUrl = computed(() => {
      let url = ''
      const lang = ctx.root.$root.$i18n.locale
      const staging = 'https://elantis-website-v1.staging.epic-sys.io'
      const prod = 'https://elantis.be'
      const path = 'elantis-privacy-policy'
      const isStaging = process.env.VUE_APP_RELEASE === 'staging' || process.env.VUE_APP_RELEASE === 'development'
      const isProd = process.env.VUE_APP_RELEASE === 'production'

      isStaging && (url = staging)
      isProd && (url = prod)

      return `${url}/${lang}/${path}`
    })

    axios.get(apiUrl.value, { withCredentials: true }).then(res => {
      container.value = res.data
    })

    return {
      container,
    }
  },
})
