





























































































































































import { computed, defineComponent, ref } from '@vue/composition-api'
import { VSelectOption } from '@/inc/types'

export default defineComponent({
  name: 'v-select',
  components: {},
  props: {
    options: {
      type: Array as () => VSelectOption[],
      default: [],
      required: true,
    },

    placeholder: {
      type: String,
      required: false,
    },

    label: String,
    name: String,
    required: Boolean,
    model: [String, Number],
  },

  setup(props, ctx) {
    const isOpen = ref(false)
    const selectedOption = ref(props.model)

    const toggle = () => (isOpen.value = !isOpen.value)
    const close = () => (isOpen.value = false)

    const firstOption = computed(() =>
      selectedOption.value ? selectedOption.value : props.placeholder || props.options[0].label
    )
    const optionList = computed(() => {
      const options = props.options.filter(option => option.label !== firstOption.value)

      if (props.required && !selectedOption.value) {
        options.unshift({ label: '', value: '' })
      }

      return options
    })

    const onClick = option => {
      selectedOption.value = option.label
      toggle()
      ctx.emit('input', option.value)
    }

    return {
      optionList,
      close,
      firstOption,
      isOpen,
      onClick,
      selectedOption,
      toggle,
    }
  },
})
